import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import { ContactForm, Name, Email } from '../components/Forms/ContactForms'
import Banner from '../components/banner'
import Seo from '../components/seo'
import NumberFormat from 'react-number-format'
import ReCAPTCHA from 'react-google-recaptcha'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ContactSeoImage from '../assets/images/direct-patient-care.jpg'

const recaptchaRef = React.createRef()

class Contact extends React.Component {
    constructor(props) {
        super(props)

        this.handleUpdate = this.handleUpdate.bind(this)

        this.state = {
            name: ``,
            email: ``,
            phone_number: ``,
            website: ``,
            error: ``,
            loading: false,
        }
    }
    
    handleUpdate = event => {
        if (event.target.name === 'name') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'email') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'website') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'phone_number') {
            this.setState({
                [event.target.name]: event.target.value.replace(/\D/g, ''),
                error: '',
            })
        }
        this.setState({
            [event.target.name]: event.target.value,
            error: '',
        })
    }
    
    onChange(value) {
        console.log('Captcha Value:', value);
    }

    render() {
        return (
            <StaticQuery
            query={graphql`
                query ContactMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            author
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                            social {
                                facebook {
                                    url
                                }
                                instagramUrl
                                linkedInUrl
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='contact'>
                    <Seo title={`Contact Us | ${data.site.siteMetadata.title}`} description={data.site.siteMetadata.description} image={ContactSeoImage} keywords={[``]} />
                    <Banner title='Contact Us' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={ContactSeoImage} />
                    <section id='content'>
                        <Container>
                            <Row>
                                <Col xs='12' sm='12' md='5' lg='5'>
                                    <div className='spacer40 hidden-xs hidden-sm'></div>
                                    <h2>Reach out today!</h2>
                                    <div className='spacer20'></div>
                                    <p><strong>{data.site.siteMetadata.title}</strong><br /><a href={`mailto:${data.site.siteMetadata.emailUrl}`}>{data.site.siteMetadata.emailUrl}</a><br />{data.site.siteMetadata.phone}</p>
                                </Col>
                                <Col xs='12' sm='12' md='7' lg='7'>
                                    <ContactForm title={`Contact Us`} subtitle={``} error={this.state.error}>
                                        <Name handleUpdate={this.handleUpdate} email={this.state.email} autoComplete='on' />
                                        <Email handleUpdate={this.handleUpdate} email={this.state.email} autoComplete='on' />
                                        <fieldset>
                                            <span className='label'>Phone Number: <em>(Optional)</em></span>
                                            <NumberFormat id='phone_number' placeholder='+1 (###) ###-####' onChange={this.handleUpdate} name='phone_number' value={this.state.phone_number} type='tel' format='+1##########' mask='_' />
                                        </fieldset>
                                        <fieldset className='required'>
                                            <textarea id='message' name='message' aria-label='message' placeholder='Type your message here...' required></textarea>
                                        </fieldset>
                                        <ReCAPTCHA ref={recaptchaRef} sitekey={data.site.siteMetadata.google.googleReCaptchaKey} onChange={this.onChange} />
                                        <div className='spacer20'></div>
                                        <button type='submit' className='btn btn-secondary btn-block' disabled={this.state.loading}>
                                            {this.state.loading ? null : 'Send'}
                                            {this.state.loading && (
                                                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                                            )}
                                            {this.state.loading ? null : (<FontAwesomeIcon icon="chevron-right" />)}
                                        </button>
                                    </ContactForm>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Contact